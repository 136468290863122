
import React, { Component } from "react";
import {observable, toJS, action} from "mobx";
import {doGql, PreJson, wrap } from "../../util/Utils";
import ErrorBanner from "../../util/ErrorBanner";
import Loading from "../../util/Loading";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import _ from "lodash";
import "./JobTasks.css";
import {Pagination} from "@mui/material";
import {TaskState} from "../../util/enum/EnumSlug";
import { jobDialogStore } from "./JobDialog";
import  {jobTaskDialogStore} from "./JobTaskDialog";

class JobTasks extends Component {
    store = observable ({
        employerId: null,
        result: null,
        results: null,
        error: null,
        loading: false,
        skip: 0,
        limit: 10,
        sort: "{}",
        // geo: "", // filter
        state: "" // filter
    });

    componentDidMount () {
        const { employer } = this.props;
        if (employer) {
            this.store.employerId = employer.id;
            this.reload ();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.employer?.id != this.props.employer.id) {
            this.reload();
        }
    }

    render () {
        const { employer } = this.props;
        if (! employer) {
            return null;
        }
        const { error, results, result, loading } = this.store;

        return (
            <div>
                <ErrorBanner error={toJS (error)} />
                <Loading show={loading} />
                {this.renderTable (toJS (result))}
            </div>
        );
    }

    renderTable (results) {
        if (!results) {
            return null;
        }
        return (
            <TableContainer component={Paper}>
                <br/><br/>
                <div className={"JobTaskTable-Header"}>
                    <div>
                        Showing {results.results.length} of {results.total} jobs
                    </div>
                    <div>
                        {this.renderPagination (results)}
                    </div>
                    <div>
                        <i className="fal fa-filter"></i>
                    </div>
                </div>
                <Table size="small" >
                    <TableHead>
                        <TableRow>
                            <TableCell>Index</TableCell>
                            <TableCell>Employer</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Geo</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.map (results.results, (el, i) => {
                            return (
                                <TableRow key={i}>
                                    <TableCell component="th" scope="row">
                                        {i + 1}.
                                    </TableCell>
                                    <TableCell>
                                        {el.job.employer.name}
                                    </TableCell>
                                    <TableCell>
                                        <span className={"Linkish"} onClick={() => {
                                            jobDialogStore.showDialog ({
                                                job: el.job,
                                                dialogProps: {
                                                    maxWidth: "lg",
                                                    fullWidth: true
                                                }
                                            });
                                        }}>
                                            {el.job.title}
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                    {el.job.geo.key}
                                    </TableCell>
                                    <TableCell>
                                        <span className={"Linkish"} onClick={() => {
                                            jobTaskDialogStore.showDialog ({
                                                jobTask: el,
                                                dialogProps: {
                                                    maxWidth: "md",
                                                    fullWidth: true
                                                }
                                            });
                                            console.log ("shown");
                                        }}>
                                            <TaskState value={el.state}/>
                                        </span>
                                    </TableCell>
                                </TableRow>
                        );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    renderPagination (results) {
        const { skip, limit, total } = results;

        return (
            <Pagination
                count={Math.ceil (total / limit)}
                page={skip / limit + 1}
                size={"large"}
                color="primary"
                onChange={action ((e, c) => {
                    this.store.skip = (c - 1) * limit;
                    this.reload();
                })}
                showFirstButton
                showLastButton
            />
        )
    };


    async reload () {
        const res = await doGql (this);
    }

    get query () {
        return `query ($req: JobTaskFindRequest!) { 
            res: findJobTasks (req: $req) { 
                total 
                skip
                limit            
                results { 
                    id
                    stateTask { attemptCount } 
                    siteTasks { 
                        attemptCount 
                        siteType
                        state
                        ajc { name } 
                        wdb { wdbName } 
                        jc { name }
                    }
                    siteTaskCount
                    state
                    job { 
                        id 
                        title
                        jobKey
                        location { city }  
                        employer { id name } 
                        geo { id key } 
                        description
                        created
                        lastModified
                        eeoStatement
                        compensation
                        requirements
                        compensation
                        subsidiary
                        onetCode
                        employmentType
                        educationType
                        state
                        location { city additional } 
                        toApply { instructions url} 
                    } 
                }
            }
        }`;
    }

    get variables () {
        const { employer } = this.props;
        const { geo, state, skip, limit, sort } = this.store;
        const filters = {
            // geo: geo || null,
            state: state || null,
            employerId: employer.id
        };
        return {
            req: {
                skip, limit, sort, filters
            }
        };
   }
}

export default wrap (JobTasks);

// EOF