import React, { Component } from "react";
import { USAMap } from '@mirawision/usa-map-react';
import _ from "lodash";
import {PreJson} from "../util/Utils";

// https://coolors.co/palettes/trending
const NONE = "#ededed";

const COLORS = [
    "#d9ed92ff",
    "#b5e48cff",
    "#99d98cff",
    "#76c893ff",
    "#52b69aff",
    "#34a0a4ff",
    "#168aadff",
    "#1a759fff",
    "#1e6091ff",
    "#184e77ff"
];

class States extends Component {
    render() {
        const { data } = this.props;
        const mapSettings = { };

        if (data?.geos) {
            const geos = { };
            let max = 0;
            _.forEach (data.geos, (el) => {
                geos[el.geo] = el.count;
                if (el.count > max) {
                    max = el.count;
                }
            });
            _.forEach (geos, (count, geo) => {
                const which = Math.floor (count / max * (COLORS.length - 1));
                mapSettings[geo] = {
                    fill: COLORS[which]
                };
            });
        }

        const defaults = {
            onClick: (state) => null,
            fill: NONE
        };

        return (
            <div className={"EmployerDashboard-States"}>
                <USAMap defaultState={defaults} customStates={mapSettings} />
            </div>
        );
    }
}

export default States;

// EOF