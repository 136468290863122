import {createHashRouter} from "react-router-dom";
import Home from "./Home";
import React from "react";
import DataBrowser from "../data/DataBrowser";
import System from "../system/System";
import GraphQLQuery from "../system/gql/GraphQLQuery";
import Tests from "../system/tests/Tests";
import UserProfile from "../user/UserProfile";
import EditUserProfile from "../user/EditUserProfile";

/**
 *
 * @type {Router}
 */

const LOGGED_IN = createHashRouter ([
    {
        path: "/",
        element: <Home />
    },
    {
        path: "/data",
        element: <DataBrowser />
    },
    {
        path: "/system",
        element: <System/>
    },
    { path: "/tests", element: <Tests /> },
    { path: "/system/gql", element: <GraphQLQuery /> },

    { path: "/profile/:id", element: <UserProfile /> },
    { path: "/profile/edit", element: <EditUserProfile /> },

    // { path: "/data/jobs", element: <FindJobs /> },
    // { path: "/data/job/add", element: <AddJob /> },
    // { path: "/data/job/:id", element: <ShowJob /> },
    // { path: "/data/job/:id/edit", element: <EditJob /> },
    // { path: "/data/job/:id/audit", element: <AuditJob /> },
    // { path: "/job/:id", element: <RenderJob /> },
]);

export default LOGGED_IN;

// EOF