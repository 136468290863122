import React, { Component } from "react";
import PieChart from "./pie/PieChart";
import Loading from "../util/Loading";

class Doughnut extends Component {
    render () {
        const { result } = this.props;
        let chartData = [0, 0, 0];
        if (result) {
            chartData = [
                result.jobTasks[0].count,
                result.jobTasks[1].count,
                result.jobTasks[2].count,
            ];
        }
        return (
            <div className={"DashboardWidget"}>
                <div className={"DashboardLabel"}>
                    Posting Status
                </div>
                {result
                    ? <PieChart chartData={chartData}/>
                    : <Loading />
                }
            </div>
        );
    }
}

export default Doughnut;

// EOF