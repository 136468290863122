import React, { Component } from "react";
import {action, observable, toJS} from "mobx";
import {invokeMaybe, PreJson, wrap} from "../../util/Utils";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {observer} from "mobx-react";
import {TaskState} from "../../util/enum/EnumSlug";
import PropertyTable from "../../util/PropertyTable";
import _ from "lodash";

class jobTaskDialogStore {
    state = observable ({
        show: false,
        // title: null,
        closeText: 'Close',
        closeFunc: null,
        dialogProps: null,
        jobTask: null,
        extra: null
    });

    get show () { return this.state.show; }
    // get title () { return this.state.title; }
    get jobTask () { return this.state.jobTask; }
    get closeText () { return this.state.closeText; }
    get closeFunc () { return this.state.closeFunc; }
    get dialogProps () { return this.state.dialogProps; }

    doClose = action (() => {
        this.state.show = false;
        invokeMaybe (this.state.closeFunc) ();
    })

    hideDialog = action (() => this.doClose ());

    showDialog = action ((obj) => {
        // this.state.title = obj.title || '.title';
        this.state.jobTask = obj.jobTask;
        this.state.closeText = obj.closeText || 'Close';
        this.state.show = true;
        this.state.dialogProps = obj.dialogProps;
        console.log (toJS (this.state));
    })
}

const store = new jobTaskDialogStore ();

const jobTaskDialog = (observer (
    class jobTaskDialog extends Component {
        getActions () {
            const actions = [];
            actions.push (
                <Button onClick={() => store.doClose ()} color="primary" key={"" + actions.length}>
                    {store.closeText || 'Close'}
                </Button>
            );
            return actions;
        }

        render() {
            const { jobTask } = store;
            if (! jobTask) {
                return null;
            }
            return (
                <Dialog
                    open={store.show}
                    {...store.dialogProps}
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        {jobTask.job.title} at {jobTask.job.employer.name}
                    </DialogTitle>
                    <DialogContent>
                        {this.renderJobTask (store.jobTask)}
                    </DialogContent>
                    <DialogActions>
                        {this.getActions()}
                    </DialogActions>
                </Dialog>
            );
        }

        renderJobTask (jobTask) {
            if (jobTask == null) {
                return null;
            }
            const o1 = {
                state: <TaskState value={jobTask.state} />,
                attempts: jobTask.stateTask.attemptCount
            };
            const o2 = { };
            _.each (jobTask.siteTasks, (el, i) => {
                switch (el.siteType) {
                    case "AJC":
                        o2[`${el.ajc.name} (AJC)`] = <TaskState value={el.state}/>;
                        break
                    case "JC":
                        o2[`${el.jc.name} (JC)`] = <TaskState value={el.state}/>;
                        break;
                    case "WDB":
                        o2[`${el.wdb.wbbName} (WDB)`] = <TaskState value={el.state}/>;
                        break;
                }
            });
            return (
                <div>
                    <h2>State Posting</h2>
                    <PropertyTable value={o1} />
                    <h2>Site Notifications</h2>
                    <PropertyTable value={o2} />
                </div>
            );
        }
    }
));

export default jobTaskDialog;

export {
    store as jobTaskDialogStore
};

// EOF