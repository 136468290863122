import React, { Component } from "react";
import { wrap, NO_SELECTION } from "../util/Utils";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import _ from "lodash";

/**
 *
 */

class EmployerPicker extends Component {


    render() {
        const { AuthManager } = this.props;
        const { employers } = AuthManager;

        if (! employers) {
            return "No employers found.";
        }

        const { value, required, onChange, hideEmpty } = this.props;

        return (
            <FormControl variant={"outlined"} fullWidth={true} size={"small"}>
                <InputLabel>
                    Employer
                </InputLabel>
                <Select
                    label={"Employer"}
                    value={value}
                    onChange={(e) => {
                        const id = e.target.value;
                        const decoded = _.find (employers, employer => employer.id == id) || null;
                        onChange (decoded);
                    }}
                >
                    {! hideEmpty && (
                        <MenuItem value={NO_SELECTION}>
                            <em>-- SELECT --</em>
                        </MenuItem>
                    )}
                    {_.map (employers, (employer, i) => {
                        return (
                            <MenuItem key={employer.id} value={employer.id}>
                                {employer.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        );
    }
}

export default wrap (EmployerPicker);

// EOF