import React, { Component } from "react";
import "./css/LoginBanner.css";

class LoginBanner extends Component {
    render() {
        const { width } = this.props;

        return (
            <div className={"LoginBanner"}>
                <div className={"LoginBanner-Title"}>
                    Abide.ai
                </div>
                <div className={"LoginBanner-Subtitle"}>
                    Employer Portal
                </div>
            </div>
        );
    }
}

LoginBanner.defaultProps = {
    width: 200
};

export default LoginBanner;

// EOF