import 'chart.js/auto';
import React from "react";
import { Pie } from "react-chartjs-2";
import { Doughnut } from 'react-chartjs-2';

function PieChart({ chartData }) {

    const data = {
        labels: [
            `Pending (${chartData[0]})`,
            `Completed (${chartData[1]})`,
            `Failed (${chartData[2]})`
        ],
        datasets: [{
            label: 'Job States',
            data: chartData,
            backgroundColor: [
                'rgb(197,197,197)',
                'rgb(114,213,9)',
                'rgb(227,54,38)'
            ],
            hoverOffset: 4
        }]
    };

    return (
        <div className="chart-container">
            <Doughnut
                // type={"doughnut"}
                data={data}
                options={{
                    // type: "doughnut",
                    plugins: {
                        legend: {
                            position: 'bottom',
                        },
                        // title: {
                        //     display: true,
                        //     text: "Users Gained between 2016-2020"
                        // }
                    }
                }}
            />
        </div>
    );
}
export default PieChart;