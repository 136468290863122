import React, {Component} from "react";
import {observable, action} from "mobx";
import {PreJson, wrap, doGql} from "../util/Utils";
import "./css/Home.css";
import EmployerPicker from "./EmployerPicker";
import {DashboardBar, DashboardWidget} from "./Dashboard";
import States from "./States";
import ErrorBanner from "../util/ErrorBanner";
import {ReloadButton} from "../util/ButtonUtil";
import Doughnut from "./Doughnut";
import JobTasks from "./jobs/JobTasks";
import JobDialog from "./jobs/JobDialog";
import {Button} from "@mui/material";
import JobTaskDialog from "./jobs/JobTaskDialog";

/**
 * The home page.
 */

class Home extends Component {
    store = observable ({
        employer: null,
        loading: false,
        results: null,
        result: null,
        error: null,
    });

    componentDidMount() {
        const {AuthManager} = this.props;
        const {employers} = AuthManager;
        if (employers && employers.length > 0) {
            this.store.employer = employers[0];
            this.loadSummary (employers[0].id);
        }
    }

    render () {
        const { employer, result, error } = this.store;

        return (
            <div>
                <div className={"PickerBar"}>
                    <EmployerPicker
                        hideEmpty={true}
                        value={employer?.id || ""}
                        onChange={action ((employer) => {
                            this.store.employer = employer;
                            this.loadSummary (employer.id);
                    })}/>
                    <Button onClick={() => this.loadSummary ()}>
                        <i className={"far fa-redo"}/>
                    </Button>

                </div>
                <ErrorBanner error={error} />

                <div className={"EmployerDashboard"}>
                    <States data={result} />
                    <div className={"EmployerDashboard-Metrics"}>
                        <DashboardWidget label={"Jobs Available"} value={result?.jobCount} />
                        <DashboardWidget label={"Job Tasks"} value={result?.jobTaskCount} />
                        <Doughnut result={result}/>
                    </div>
                    <div className={"EmployerDashboard-Jobs"}>

                    </div>
                </div>
                <JobTasks employer={employer} />
                <br/><br/>
                <JobDialog />
                <JobTaskDialog />
            </div>
        );
    }

    async loadSummary () {
        const result = await doGql (this);
    }

    get query () {
        return `query ($employerId: String!) {
            res: getEmployerSummary (employerId: $employerId) {
                jobCount
                jobTaskCount
                missingTasks
                jobTasks { 
                    state
                    count 
                }         
                geos { 
                    geo 
                    count
                }           
            } 
        }`;
    }

    get variables () {
        const { employer } = this.store;
        return {
            employerId: employer.id
        };
    }
}

export default wrap(Home);

// EOF