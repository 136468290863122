/**
 * Used to manage the login state of the client and the associated
 * tokens needed for operations against the server.
 */
import {action, observable} from "mobx";
import Server from "./Server";
import _ from "lodash";
import {infoDialogStore} from "./InfoDialog";

class AuthManager {
    store = observable ({
        csrfToken: null,
        user: null,
        employers: []
    });

    setLogin = action ((res) => {
        const { user, csrfToken, employers } = res;
        console.log ("res", res);
        this.store.user = user;
        this.store.csrfToken = csrfToken;
        this.store.employers = employers;
        // Server.csrfToken = csrfToken;
        localStorage.setItem ("csrfToken", csrfToken);
        this.notifyLogin (user);
        return;
    });

    loginGoogle = action (async (success) => {
        const mutation = `
            mutation ($success: GoogleSuccessInput!) {
              res: loginGoogle (success: $success) {
                csrfToken
                user {
                    id
                    email
                    name
                }
                token {
                  version
                  uid
                  expires
                  signature
                  encoded
                }
              }
            }`;
        const variables = { success };
        try {
            const res = await Server._gql (mutation, variables);
            const { user, csrfToken, employers } = res;
            this.setLogin ({
                user, csrfToken, employers
            });
        }
        catch (e) {
            infoDialogStore.showError(e);
        }
    });

    loginLink = action (async (email, token) => {
        const mutation = `
            mutation ($email: String!, $token: String!) {
              res: loginLink (email: $email, token: $token) {
                csrfToken
                user {
                    id
                    email
                    name
                }
                token {
                  version
                  uid
                  expires
                  signature
                  encoded
                }
              }
            }`;
        const variables = {
            email, token
        };
        try {
            const res = await Server._gql (mutation, variables);
            this.setLogin (res);
        }
        catch (e) {
            infoDialogStore.showError(e);
        }
    });

    login = action (async (email, password) => {
        const mutation = `
            mutation ($email: String!, $password: String!) {
              res: loginPassword (email: $email, password: $password) {
                csrfToken
                user {
                    id
                    email
                    name
                }
                employers {
                    id
                    name
                    key
                    domain
                    isActive 
                }
                token {
                  version
                  uid
                  expires
                  signature
                  encoded
                }
              }
            }`;
        const variables = {
            email, password
        };
        try {
            const res = await Server._gql(mutation, variables);
            this.setLogin (res);
        }
        catch (e) {
            infoDialogStore.showError(e);
        }
    });

    logout = action (() => {
        this.store.csrfToken = null;
        this.store.user = null;
        this.store.employers = [];
        // Server.csrfToken = null;
        localStorage.removeItem ("session");
        this.notifyLogout();
    });

    get user () {
        return this.store.user;
    }

    get csrfToken () {
        return this.store.csrfToken;
    }

    get employers () {
        return this.store.employers;
    }

    get isLoggedIn () {
        return this.store.csrfToken !== null;
    }

    // Add support for listeners that want to be notified of successful logins.

    loginListeners = [];
    addLoginListener = action ((ler) => this.loginListeners.push (ler));
    notifyLogin = (user) => {
        _.forEach (this.loginListeners, (ler) => {
            ler (user);
        })
    };

    // Add support for listeners that want to be notified of logouts.

    logoutListeners = []
    addLogoutListener = action ((ler) => this.logoutListeners.push (ler));
    notifyLogout = () => {
        _.forEach (this.logoutListeners, (ler) => {
            ler ();
        });
    }
}

const AUTH_MANAGER = new AuthManager ();

// Example use of the login and logout listener capability.
AUTH_MANAGER.addLogoutListener (() => console.log ("LOGOUT"));
AUTH_MANAGER.addLoginListener ((user) => console.log ("LOGIN", user));

export default AUTH_MANAGER;

// EOF