import React, { Component } from "react";
import {action, observable} from "mobx";
import {invokeMaybe, PreJson, wrap, toBullets, formatDate} from "../../util/Utils";
import _ from "lodash";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {observer} from "mobx-react";
import PropertyTable from "../../util/PropertyTable";
import {JobState} from "../../util/enum/EnumSlug";

class JobDialogStore {
    state = observable ({
        show: false,
        title: null,
        closeText: 'Close',
        closeFunc: null,
        dialogProps: null,
        job: null,
        extra: null
    });

    get show () { return this.state.show; }
    get title () { return this.state.title; }
    get job () { return this.state.job; }
    get closeText () { return this.state.closeText; }
    get closeFunc () { return this.state.closeFunc; }
    get dialogProps () { return this.state.dialogProps; }

    doClose = action (() => {
        this.state.show = false;
        invokeMaybe (this.state.closeFunc) ();
    })

    hideDialog = action (() => this.doClose ());

    showDialog = action ((obj) => {
        this.state.title = obj.title || '.title';
        this.state.job = obj.job;
        this.state.closeText = obj.closeText || 'Close';
        this.state.show = true;
        this.state.dialogProps = obj.dialogProps;
    })
}

const store = new JobDialogStore ();

const JobDialog = (observer (
    class JobDialog extends Component {
        getActions() {
            const actions = [];
            actions.push(
                <Button onClick={() => store.doClose()} color="primary" key={"" + actions.length}>
                    {store.closeText || 'Close'}
                </Button>
            );
            return actions;
        }

        render() {
            const {job} = store;
            if (!job) {
                return null;
            }
            return (
                <Dialog
                    // onClose={this.handleClose}
                    open={store.show}
                    {...store.dialogProps}
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        {`${store.job.title} at ${store.job.employer.name}`}
                    </DialogTitle>
                    <DialogContent>
                        {this.renderJob(store.job)}
                    </DialogContent>
                    <DialogActions>
                        {this.getActions()}
                    </DialogActions>
                </Dialog>
            );
        }

        renderJob(job) {
            if (job == null) {
                return null;
            }
            const o = {
                employer: job.employer.name,
                jobKey: job.jobKey,
                title: job.title,
                location: job.location.city + ", " + job.geo.key,
                description: <div dangerouslySetInnerHTML={{__html: job.description}}/>,
                toApply: job.toApply.url,
                "toApply.instructions": job.toApply.instructions,
                "toApply.contact": job.toApply.contact || '-',
                requirements: toBullets(job.requirements),
                compensation: job.compensation || "-",
                eeoStatement: job.eeoStatement || "-",
                // state: <JobState value={job.state}/>,
                subsidiary: job.subsidiary || '-',
                onetCode: job.onetCode || '-',
                educationType: job.educationType || '-',
                employmentType: this.encodeStrings(job.employmentType) || '-',
                created: job.created,
                lastModified: job.lastModified,
            };
            formatDate (o, [ "created", "lastModified" ]);

            return (
                <div>
                    <PropertyTable value={o}/>
                </div>
            );
        }

        encodeStrings(els) {
            let str = "";
            if (els) {
                for (const el of els) {
                    if (str) {
                        str += ", ";
                    }
                    str += el;
                }
            }
            return str;
        }
    }
));

export default JobDialog;

export {
    store as jobDialogStore
};

// EOF